import React from 'react';
import { MutableSnapshot, RecoilRoot, RecoilRootProps } from 'recoil';
import { RecoilSubscriptionManager_INTERNAL } from './RecoilSubscriptionManager_INTERNAL';
import { RelayEnvironmentStore_INTERNAL } from '../recoil/relayEnvironmentStore_INTERNAL';
import { EnvironmentManager_INTERNAL } from './EnvironmentManager_INTERNAL';
import { AxiosEnvironmentStore_INTERNAL } from '../recoil/axiosEnvironmentStore_INTERNAL';
import { RecoilRouteSync_INTERNAL } from './RecoilRouteSync_INTERNAL';
import { Config } from '../factories/createDataFetchingConfig';
import { dataFetchingConfigContext_INTERNAL } from '../contexts/configContext';
import { hrefAtom_INTERNAL } from '../recoil/routeSelector';
import { Environment } from 'relay-runtime';

export type DataFetchingProviderProps = RecoilRootProps & {
  config: Config;
  initialEntries?: string[];
  initializeState?: (mutableSnapshot: MutableSnapshot) => void;
  registerAdditionalRelayEnvironmentsCallback?: () =>
    | Record<string, () => Promise<Environment>>
    | undefined;
};

export const DataFetchingProvider: React.FC<DataFetchingProviderProps> = ({
  children,
  config,
  registerAdditionalRelayEnvironmentsCallback,
  ...props
}) => {
  return (
    <EnvironmentManager_INTERNAL
      store={RelayEnvironmentStore_INTERNAL}
      defaultEnvironment={config.relayDefaultEnvironment}
      environments={{
        ...config.relayEnvironments,
        ...registerAdditionalRelayEnvironmentsCallback?.(),
      }}
    >
      <EnvironmentManager_INTERNAL
        store={AxiosEnvironmentStore_INTERNAL}
        defaultEnvironment={config.axiosDefaultEnvironment}
        environments={config.axiosEnvironments}
      >
        <RecoilRoot
          initializeState={(mutableSnapshot) => {
            if (__TEST__) {
              const { hash, pathname, search } = new URL(
                `${window.document.location.origin}${
                  props.initialEntries?.[0] ?? '/'
                }`
              );
              mutableSnapshot.set(hrefAtom_INTERNAL, {
                hash,
                pathname,
                search: search.replace(/^\?/, ''),
              });
            }

            props.initializeState?.(mutableSnapshot);
          }}
        >
          <RecoilSubscriptionManager_INTERNAL />
          <RecoilRouteSync_INTERNAL
            url={
              __TEST__
                ? `${window.document.location.origin}${
                    props.initialEntries?.[0] ?? '/'
                  }`
                : undefined
            }
          >
            <dataFetchingConfigContext_INTERNAL.Provider
              value={{
                defaultErrorBehavior: config.defaultErrorBehavior,
                defaultErrorBoundary: config.defaultErrorBoundary,
                suspenseWrapper: config.suspenseWrapper,
                resumeWrapper: config.resumeWrapper,
              }}
            >
              {children}
            </dataFetchingConfigContext_INTERNAL.Provider>
          </RecoilRouteSync_INTERNAL>
        </RecoilRoot>
      </EnvironmentManager_INTERNAL>
    </EnvironmentManager_INTERNAL>
  );
};
