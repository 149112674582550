import {
  RecoilState,
  RecoilValue,
  RecoilValueReadOnly,
  SerializableParam,
  atomFamily as ogAtomFamily,
  atom as ogAtom,
  DefaultValue,
  Loadable,
  WrappedValue,
  selectorFamily as ogSelectorFamily,
  selector as ogSelector,
  GetRecoilValue,
  SetRecoilState,
  ResetRecoilState,
  ReadOnlySelectorFamilyOptions,
  ReadWriteSelectorFamilyOptions,
  AtomFamilyOptions,
  AtomEffect,
  waitForAll as ogWaitForAll,
  noWait as ogNoWait,
} from 'recoil';
import { triggerSubscription } from '../subscriptionStore_INTERNAL';

export type ScopeStore = Record<string, string>;

export type ScopedNode<RecoilNode = RecoilValue<any>> = {
  (scope: ScopeStore): RecoilNode;
  key: string;
  $$type: 'ScopedNode';
};

export type ScopedNodeFamily<NodeFamilyType extends (...args: any[]) => any> = (
  ...params: Parameters<NodeFamilyType>
) => ScopedNode<ReturnType<NodeFamilyType>>;

export const isScopedNode = (node: any): node is ScopedNode => {
  if (typeof node !== 'function') {
    return false;
  }
  return '$$type' in node && node.$$type === 'ScopedNode';
};

export const transformNodeFamilyIntoScopedNodeFamily =
  <
    T,
    P extends SerializableParam,
    RecoilNode extends RecoilValue<T> = RecoilValue<T>
  >(
    nodeFamily: (variables: { scope: ScopeStore; params: P }) => RecoilNode
  ) =>
  (params: P) => {
    const scopedNode: ScopedNode<RecoilNode> = (scope) =>
      nodeFamily({ scope, params });
    scopedNode.$$type = 'ScopedNode';
    scopedNode.key = nodeFamily({ params, scope: {} }).key;
    return scopedNode;
  };

export type CherreValueReadOnly<T> =
  | RecoilValueReadOnly<T>
  | ScopedNode<RecoilValueReadOnly<T>>;

export type CherreState<T> = RecoilState<T> | ScopedNode<RecoilState<T>>;

export type CherreValue<T> = RecoilValue<T> | ScopedNode<RecoilValue<T>>;

export type GetCherreValue = <T>(node: CherreValue<T>) => T;

export type SetCherreState = <T>(
  recoilVal: CherreState<T>,
  newVal: T | DefaultValue | ((prevValue: T) => T | DefaultValue)
) => void;

export type ResetCherreState = <T>(recoilVal: CherreState<T>) => void;

type CherreGetInterface = {
  get: GetCherreValue;
};

type RecoilGetInterface = {
  get: GetRecoilValue;
};

type CherreSetInterface = {
  get: GetCherreValue;
  set: SetCherreState;
  reset: ResetCherreState;
};

type RecoilSetInterface = {
  get: GetRecoilValue;
  set: SetRecoilState;
  reset: ResetRecoilState;
};

export const transformRecoilGetToCherreGet = (
  get: GetRecoilValue,
  scope: ScopeStore | null
): GetCherreValue => {
  return (node: CherreValue<any>) => {
    if (isScopedNode(node)) {
      if (!scope) {
        throw new Error(
          `A non scoped selector cannot be used to get a value of a scoped selector`
        );
      }
      triggerSubscription(node(scope));
      return get(node(scope));
    }
    triggerSubscription(node);
    return get(node);
  };
};

export const transformRecoilSetToCherreSet = (
  set: SetRecoilState,
  scope: ScopeStore | null
): SetCherreState => {
  return <T>(
    node: CherreState<T>,
    newValue: T | DefaultValue | ((prevValue: T) => T | DefaultValue)
  ) => {
    if (isScopedNode(node)) {
      if (!scope) {
        throw new Error(
          `A non scoped selector cannot be used to get a value of a scoped selector`
        );
      }
      return set(node(scope), newValue);
    }
    return set(node, newValue);
  };
};

export const transformRecoilResetToCherreReset = (
  reset: ResetRecoilState,
  scope: ScopeStore | null
): ResetCherreState => {
  return <T>(node: CherreState<T>) => {
    if (isScopedNode(node)) {
      if (!scope) {
        throw new Error(
          `A non scoped selector cannot be used to get a value of a scoped selector`
        );
      }
      return reset(node(scope));
    }
    return reset(node);
  };
};

interface CherreReadOnlyNonScopedSelectorOptions<T> {
  key: string;
  get: (
    options: RecoilGetInterface
  ) => Promise<T> | RecoilValue<T> | Loadable<T> | WrappedValue<T> | T;
}

interface CherreReadOnlyScopedSelectorOptions<T> {
  key: string;
  scoped: true;
  get: (
    scope: ScopeStore
  ) => (
    options: CherreGetInterface
  ) => Promise<T> | CherreValue<T> | Loadable<T> | WrappedValue<T> | T;
}

interface CherreReadWriteNonScopedSelectorOptions<T>
  extends CherreReadOnlyNonScopedSelectorOptions<T> {
  set: (options: RecoilSetInterface, newValue: T | DefaultValue) => void;
}

interface CherreReadWriteScopedSelectorOptions<T>
  extends CherreReadOnlyScopedSelectorOptions<T> {
  set: (
    scope: ScopeStore
  ) => (options: CherreSetInterface, newValue: T | DefaultValue) => void;
}

export function selector<T>(
  options: CherreReadWriteScopedSelectorOptions<T>
): ScopedNode<RecoilState<T>>;
export function selector<T>(
  options: CherreReadOnlyScopedSelectorOptions<T>
): ScopedNode<RecoilValueReadOnly<T>>;
export function selector<T>(
  options: CherreReadOnlyNonScopedSelectorOptions<T>
): RecoilValueReadOnly<T>;
export function selector<T>(
  options: CherreReadWriteNonScopedSelectorOptions<T>
): RecoilState<T>;
export function selector<T>(options): any {
  type RO = ReadOnlySelectorFamilyOptions<T, ScopeStore>;
  type RW = ReadWriteSelectorFamilyOptions<T, ScopeStore>;
  if ('scoped' in options) {
    const _options: RO = {
      key: options.key,
      get:
        (scope) =>
        ({ get }) => {
          const result = options.get(scope)({
            get: transformRecoilGetToCherreGet(get, scope),
          });
          if (isScopedNode(result)) {
            return result(scope);
          }
          return result;
        },
    };
    if ('set' in options) {
      (_options as RW).set =
        (scope) =>
        ({ get, set, reset }, newValue) => {
          return options.set(scope)(
            {
              get: transformRecoilGetToCherreGet(get, scope),
              set: transformRecoilSetToCherreSet(set, scope),
              reset: transformRecoilResetToCherreReset(reset, scope),
            },
            newValue
          );
        };
    }
    const innerSelector = ogSelectorFamily<T, ScopeStore>(_options);
    return Object.assign(innerSelector, {
      $$type: 'ScopedNode' as const,
      key: innerSelector({}).key,
    });
  }
  return ogSelector<T>(options);
}

interface CherreReadOnlyNonScopedSelectorFamilyOptions<
  T,
  P extends SerializableParam
> {
  key: string;
  get: (
    params: P
  ) => (
    options: RecoilGetInterface
  ) => Promise<T> | RecoilValue<T> | Loadable<T> | WrappedValue<T> | T;
}

interface CherreReadOnlyScopedSelectorFamilyOptions<
  T,
  P extends SerializableParam
> {
  key: string;
  scoped: true;
  get: (
    params: P,
    scope: ScopeStore
  ) => (
    options: CherreGetInterface
  ) => Promise<T> | CherreValue<T> | Loadable<T> | WrappedValue<T> | T;
}

interface CherreReadWriteNonScopedSelectorFamilyOptions<
  T,
  P extends SerializableParam
> extends CherreReadOnlyNonScopedSelectorFamilyOptions<T, P> {
  set: (
    params: P
  ) => (options: RecoilSetInterface, newValue: T | DefaultValue) => void;
}

interface CherreReadWriteScopedSelectorFamilyOptions<
  T,
  P extends SerializableParam
> extends CherreReadOnlyScopedSelectorFamilyOptions<T, P> {
  set: (
    params: P,
    scope: ScopeStore
  ) => (options: CherreSetInterface, newValue: T | DefaultValue) => void;
}

export function selectorFamily<T, P extends SerializableParam>(
  options: CherreReadOnlyScopedSelectorFamilyOptions<T, P>
): (params: P) => ScopedNode<RecoilValueReadOnly<T>>;
export function selectorFamily<T, P extends SerializableParam>(
  options: CherreReadWriteNonScopedSelectorFamilyOptions<T, P>
): (params: P) => RecoilState<T>;
export function selectorFamily<T, P extends SerializableParam>(
  options: CherreReadOnlyNonScopedSelectorFamilyOptions<T, P>
): (params: P) => RecoilValueReadOnly<T>;
export function selectorFamily<T, P extends SerializableParam>(
  options: CherreReadWriteScopedSelectorFamilyOptions<T, P>
): (params: P) => ScopedNode<RecoilState<T>>;
export function selectorFamily<T, P extends SerializableParam>(
  options: any
): any {
  type InnerParams = { scope: ScopeStore; params: P };
  type RO = ReadOnlySelectorFamilyOptions<T, InnerParams>;
  type RW = ReadWriteSelectorFamilyOptions<T, InnerParams>;
  if ('scoped' in options) {
    const _options: RO = {
      key: options.key,
      get:
        ({ scope, params }) =>
        ({ get }) => {
          const result = options.get(
            params,
            scope
          )({
            get: transformRecoilGetToCherreGet(get, scope),
          });
          if (isScopedNode(result)) {
            return result(scope);
          }
          return result;
        },
    };
    if ('set' in options) {
      (_options as RW).set =
        ({ scope, params }) =>
        ({ get, set, reset }, newValue) => {
          return options.set(params)(scope)(
            {
              get: transformRecoilGetToCherreGet(get, scope),
              set: transformRecoilSetToCherreSet(set, scope),
              reset: transformRecoilResetToCherreReset(reset, scope),
            },
            newValue
          );
        };
    }
    const innerSelectorFamily = ogSelectorFamily<T, InnerParams>(_options);
    return transformNodeFamilyIntoScopedNodeFamily(innerSelectorFamily);
  }
  return ogSelectorFamily<T, P>(options);
}

interface CherreNonScopedAtomOptions<T> {
  key: string;
  default?: Promise<T> | RecoilValue<T> | Loadable<T> | WrappedValue<T> | T;
  effects?: Array<AtomEffect<T>>;
}

interface CherreScopedAtomOptions<T> {
  key: string;
  scoped: true;
  default?: (
    scope: ScopeStore
  ) => Promise<T> | CherreValue<T> | Loadable<T> | WrappedValue<T> | T;
  effects?: (scope: ScopeStore) => Array<AtomEffect<T>>;
}

export function atom<T>(options: CherreNonScopedAtomOptions<T>): RecoilState<T>;
export function atom<T>(
  options: CherreScopedAtomOptions<T>
): ScopedNode<RecoilState<T>>;
export function atom<T>(options) {
  if ('scoped' in options) {
    const _options: AtomFamilyOptions<T, ScopeStore> = {
      key: options.key,
      effects: options.effects,
    };
    if ('default' in options) {
      (_options as any).default = (scope) => {
        const result = options.default!(scope);
        if (isScopedNode(result)) {
          return result(scope);
        }
        return result;
      };
    }
    const innerAtom = ogAtomFamily<T, ScopeStore>(_options);
    return Object.assign(innerAtom, {
      $$type: 'ScopedNode' as const,
      key: innerAtom({}).key,
    });
  }
  return ogAtom<T>(options);
}

interface CherreNonScopedAtomFamilyOptions<T, P extends SerializableParam> {
  key: string;
  default?: (
    params: P
  ) => Promise<T> | RecoilValue<T> | Loadable<T> | WrappedValue<T> | T;
  effects?: (params: P) => Array<AtomEffect<T>>;
}

interface CherreScopedAtomFamilyOptions<T, P extends SerializableParam> {
  key: string;
  scoped: true;
  default?: (
    params: P,
    scope: ScopeStore
  ) => Promise<T> | CherreValue<T> | Loadable<T> | WrappedValue<T> | T;
  effects?: (params: P, scope: ScopeStore) => Array<AtomEffect<T>>;
}

export function atomFamily<T, P extends SerializableParam>(
  options: CherreNonScopedAtomFamilyOptions<T, P>
): (params: P) => RecoilState<T>;
export function atomFamily<T, P extends SerializableParam>(
  options: CherreScopedAtomFamilyOptions<T, P>
): (params: P) => ScopedNode<RecoilState<T>>;
export function atomFamily<T, P extends SerializableParam>(
  options:
    | CherreNonScopedAtomFamilyOptions<T, P>
    | CherreScopedAtomFamilyOptions<T, P>
): (params: P) => CherreState<T> {
  if ('scoped' in options) {
    type InnerInput = { scope: ScopeStore; params: P };
    const _options: AtomFamilyOptions<T, InnerInput> = {
      key: options.key,
    };
    if ('default' in options) {
      (_options as any).default = ({ scope, params }: InnerInput) => {
        const result = options.default!(params, scope);
        if (isScopedNode(result)) {
          return result(scope);
        }
        return result;
      };
    }
    if ('effects' in options) {
      (_options as any).effects = ({ scope, params }: InnerInput) => {
        return options.effects!(params, scope);
      };
    }
    const innerAtomFamily = ogAtomFamily<T, InnerInput>(_options);
    return transformNodeFamilyIntoScopedNodeFamily(innerAtomFamily);
  }
  return ogAtomFamily<T, P>(options);
}

export type UnwrapCherreValue<T> = T extends CherreValue<infer R> ? R : never;

export type UnwrapCherreValues<
  T extends Array<CherreValue<any>> | { [key: string]: CherreValue<any> }
> = {
  [P in keyof T]: UnwrapCherreValue<T[P]>;
};

export function waitForAll<
  CherreValues extends Array<CherreValue<any>> | [CherreValue<any>]
>(
  param: CherreValues
): ScopedNode<RecoilValueReadOnly<UnwrapCherreValues<CherreValues>>> {
  const node: any = (scope) => {
    const nodes = param.map((n) => {
      if (isScopedNode(n)) {
        triggerSubscription(n(scope));
        return n(scope);
      }
      triggerSubscription(n);
      return n;
    });
    return ogWaitForAll(nodes);
  };
  node.$$type = 'ScopedNode';
  node.key = ogWaitForAll(param.map((n) => (isScopedNode(n) ? n({}) : n))).key;
  return node;
}

export function noWait<T>(
  state: CherreValue<T>
): ScopedNode<RecoilValueReadOnly<Loadable<T>>> {
  const node: any = (scope) => {
    if (isScopedNode(state)) {
      triggerSubscription(state(scope));
      return ogNoWait(state(scope));
    }
    triggerSubscription(state);
    return ogNoWait(state);
  };
  node.$$type = 'ScopedNode';
  node.key = ogNoWait(isScopedNode(state) ? state({}) : state).key;
  return node;
}
