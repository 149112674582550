import { AxiosInstance } from 'axios';
import { Environment } from 'relay-runtime';
import { DataFetchingConfig } from '../contexts/configContext';

export type Config = DataFetchingConfig & {
  relayDefaultEnvironment: () => Promise<Environment>;
  axiosDefaultEnvironment: () => Promise<AxiosInstance>;
  relayEnvironments?: Record<string, () => Promise<Environment>>;
  axiosEnvironments?: Record<string, () => Promise<AxiosInstance>>;
};

export function createDataFetchingConfig(input: Config): Config {
  return input;
}
