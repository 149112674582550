import { CherreError } from '@cherre-frontend/core';
import { CheckFailure } from '@recoiljs/refine';
import { AxiosError } from 'axios';
import { RecoilValue } from 'recoil';

export class DataFetchingError extends CherreError {
  constructor(
    public node: RecoilValue<unknown>,
    public originalError: unknown
  ) {
    const message =
      originalError instanceof Error
        ? originalError.message
        : `Recoil node ${node.key} throw an error`;
    super(message);
    if (originalError instanceof Error) {
      this.message = originalError.message;
      this.name = originalError.name;
      this.stack = originalError.stack;
    }
  }
}

export class RefinementError extends CherreError {
  constructor(result: CheckFailure) {
    super(result.message);
  }
}

export class FetchError extends CherreError {
  constructor(
    public url: string,
    public cherreRequestId: string,
    public error: AxiosError
  ) {
    super(
      `fetch failed for URL ${url}, cherreRequestId: ${cherreRequestId}, statusCode: ${
        error.response?.status || 'unknown'
      }`
    );
  }
}

export class NotFoundError extends CherreError {}
export class ForbiddenError extends CherreError {}
