import { useContext } from 'react';
import { dataFetchingConfigContext_INTERNAL } from '../contexts/configContext';

export const useDataFetchingConfig_INTERNAL = () => {
  const config = useContext(dataFetchingConfigContext_INTERNAL);
  if (!config) {
    throw new Error(
      `useDataFetchingConfig_INTERNAL is being used outside of a DataFetchingProvider`
    );
  }
  return config;
};
