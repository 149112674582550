import {
  AtomEffect,
  AtomFamilyOptions,
  DefaultValue,
  GetRecoilValue,
  RecoilValueReadOnly,
  SerializableParam,
  atomFamily,
  selectorFamily,
} from 'recoil';
import { CherreLoadingValue } from './CherreLoadingValue';
import { loadingEffect } from './loadingEffect';
import { resetCacheEffect } from './resetCacheEffect';
import { CherreSwrController } from './CherreSwrController';
import { setSubscriptionPair } from '../subscriptionStore_INTERNAL';

export type V_Inputs =
  | []
  | [SerializableParam]
  | [input?: SerializableParam | undefined];

type AsyncValue<R> = { result?: R; error?: unknown };

export type InternalValue<R> = CherreLoadingValue<R> | AsyncValue<R>;

type WithMR<R, MR, V> = {
  mapResponse: (rawResponse: R, variables: V) => MR;
};

type WithoutMR = {
  mapResponse?: never;
};

type WithMV<
  Vs extends V_Inputs,
  MV extends SerializableParam,
  G = GetRecoilValue
> = {
  mapVariables: (...inputs: Vs) => (rf: { get: G }) => MV;
};

type WithoutMV = {
  mapVariables?: never;
};

type AtomFamilyOptionsBase<R, V extends SerializableParam> = Omit<
  AtomFamilyOptions<R, V>,
  'default' | 'effects_UNSTABLE' | 'effects'
>;

type AsyncAtomFamilyOptionsBase<
  R,
  V extends SerializableParam
> = AtomFamilyOptionsBase<R, V> & {
  resetCache?: boolean;
  swr?: boolean | ((oldV: any, newV: any) => boolean);
  effects?: (params: V) => AtomEffect<InternalValue<R>>[];
};

type OptionsWithMRWithMV<
  R,
  MR,
  Vs extends V_Inputs,
  MV extends SerializableParam,
  G = GetRecoilValue
> = AsyncAtomFamilyOptionsBase<R, MV> & WithMR<R, MR, MV> & WithMV<Vs, MV, G>;

type OptionsWithMRWithoutMV<
  R,
  MR,
  V extends SerializableParam
> = AsyncAtomFamilyOptionsBase<R, V> & WithMR<R, MR, V> & WithoutMV;

type OptionsWithoutMRWithMV<
  R,
  Vs extends V_Inputs,
  MV extends SerializableParam,
  G = GetRecoilValue
> = AsyncAtomFamilyOptionsBase<R, MV> & WithoutMR & WithMV<Vs, MV, G>;

type OptionsWithoutMRWithoutMV<
  R,
  V extends SerializableParam
> = AsyncAtomFamilyOptionsBase<R, V> & WithoutMR & WithoutMV;

export type AsyncAtomFamilyPossibleOptions<
  R,
  MR,
  Vs extends V_Inputs,
  MV extends SerializableParam,
  G = GetRecoilValue
> = {
  withMRwithMV: OptionsWithMRWithMV<R, MR, Vs, MV, G>;
  withMRwithoutMV: OptionsWithMRWithoutMV<R, MR, MV>;
  withoutMRwithMV: OptionsWithoutMRWithMV<R, Vs, MV, G>;
  withoutMRwithoutMV: OptionsWithoutMRWithoutMV<R, MV>;
};

export type AsyncAtomFamilyOptions<
  R,
  MR,
  Vs extends V_Inputs,
  MV extends SerializableParam,
  G = GetRecoilValue
> =
  | OptionsWithMRWithMV<R, MR, Vs, MV, G>
  | OptionsWithMRWithoutMV<R, MR, MV>
  | OptionsWithoutMRWithMV<R, Vs, MV, G>
  | OptionsWithoutMRWithoutMV<R, MV>;

type ReturnWithMRWithMV<MR, Vs extends V_Inputs> = (
  ...inputs: Vs
) => RecoilValueReadOnly<MR>;

type ReturnWithMRWithoutMV<MR, V extends SerializableParam> = (
  input: V
) => RecoilValueReadOnly<MR>;

type ReturnWithoutMRWithMV<R, Vs extends V_Inputs> = (
  ...inputs: Vs
) => RecoilValueReadOnly<R>;

type ReturnWithoutMRWithoutMV<R, V> = (input: V) => RecoilValueReadOnly<R>;

export type AsyncAtomFamilyPossibleReturns<
  R,
  MR,
  Vs extends V_Inputs,
  MV extends SerializableParam
> = {
  withMRwithMV: ReturnWithMRWithMV<MR, Vs>;
  withMRwithoutMV: ReturnWithMRWithoutMV<MR, MV>;
  withoutMRwithMV: ReturnWithoutMRWithMV<R, Vs>;
  withoutMRwithoutMV: ReturnWithoutMRWithoutMV<R, MV>;
};

export function asyncAtomFamily<
  R,
  MR,
  Vs extends V_Inputs,
  MV extends SerializableParam,
  G = GetRecoilValue
>(options: OptionsWithMRWithMV<R, MR, Vs, MV, G>): ReturnWithMRWithMV<MR, Vs>;

export function asyncAtomFamily<R, MR, V extends SerializableParam>(
  options: OptionsWithMRWithoutMV<R, MR, V>
): ReturnWithMRWithoutMV<MR, V>;

export function asyncAtomFamily<
  R,
  Vs extends V_Inputs,
  MV extends SerializableParam,
  G = GetRecoilValue
>(options: OptionsWithoutMRWithMV<R, Vs, MV, G>): ReturnWithoutMRWithMV<R, Vs>;

export function asyncAtomFamily<R, V extends SerializableParam>(
  options: OptionsWithoutMRWithoutMV<R, V>
): ReturnWithoutMRWithoutMV<R, V>;

export function asyncAtomFamily<
  R,
  MR,
  Vs extends V_Inputs,
  MV extends SerializableParam,
  G = GetRecoilValue
>(options: AsyncAtomFamilyOptions<R, MR, Vs, MV, G>) {
  const swr = options.swr === false ? false : true;
  const swrPredicate =
    typeof options.swr === 'function' ? options.swr : () => true;
  const resetCache = options.resetCache ?? true;
  const swrController = swr
    ? new CherreSwrController<R | MR, Vs[0] | MV>()
    : undefined;
  const effects: (params: MV) => readonly AtomEffect<InternalValue<R>>[] = (
    params
  ) => {
    const _effects = [loadingEffect()] as AtomEffect<InternalValue<R>>[];
    if (resetCache) {
      _effects.push(
        resetCacheEffect({
          key: options.key,
          resetCallback: () => swrController?.resetSwr(),
        })
      );
    }
    if (Array.isArray(options.effects)) {
      _effects.push(...options.effects);
    }
    if (typeof options.effects === 'function') {
      _effects.push(...options.effects(params));
    }
    return _effects;
  };

  const internalAtomFamily = atomFamily<InternalValue<R>, MV>({
    ...options,
    default: () => new CherreLoadingValue(),
    effects,
  });
  const mapResponse = options.mapResponse ?? ((r: R) => r);
  const mapVariables = options.mapVariables ?? ((v: MV) => () => v);
  const internalSelectorFamily = selectorFamily({
    key: `Wrapper(${options.key})`,
    get:
      (variables: any) =>
      ({ get }) => {
        const mappedVariables = mapVariables(variables)({ get } as any);
        const internalAtom = internalAtomFamily(mappedVariables);
        setSubscriptionPair(internalAtom, internalSelectorFamily(variables));
        const value = get(internalAtom);
        if (value instanceof CherreLoadingValue) {
          throw value.getPromise();
        }
        const { result, error } = value;
        if (error) {
          throw error;
        }
        if (!result) {
          throw new Error(`empty response`);
        }
        const mappedResult = mapResponse(result, mappedVariables);
        swrController?.setNodeSet(
          internalSelectorFamily(variables),
          mappedResult
        );
        return mappedResult;
      },
    set:
      (variables: any) =>
      ({ reset, get }, newValue) => {
        if (newValue instanceof DefaultValue) {
          const mappedVariables = mapVariables(variables)({ get } as any);
          reset(internalAtomFamily(mappedVariables));
          return;
        }
        throw new Error(`AsyncAtoms are readonly`);
      },
  });
  swrController?.setNodeFamily(internalSelectorFamily);
  swrController?.setSwrPredicate((oldV: any, newV: any) =>
    swrPredicate(oldV, newV)
  );
  return swrController?.familyStub() ?? internalSelectorFamily;
}
