import {
  AppContext,
  EventContext,
  EventOptions,
  useCherreEventWithFlow,
} from '@cherre-frontend/core';
import { RecoilValue, useRecoilCallback } from 'recoil';
import {
  CherreValue,
  ResetCherreState,
  ScopeStore,
  ScopedNode,
  SetCherreState,
  isScopedNode,
  triggerSubscription,
} from '../recoil';
import { useContext, useRef } from 'react';
import { recoilScopeContext } from '../contexts/recoilScopeContext';

export interface CherreCallbackInterface {
  set: SetCherreState;
  reset: ResetCherreState;
  refresh: (node: CherreValue<any>) => void;
  getPromise: <T>(node: CherreValue<T>) => Promise<T>;
}

const resNodeFactory =
  (scope: ScopeStore) =>
  <T, Node extends RecoilValue<T>>(node: Node | ScopedNode<Node>) =>
    isScopedNode(node) ? node(scope) : node;

export interface EventWithRecoilContext extends EventContext {
  recoil: CherreCallbackInterface;
}

export interface CallbackRecoilContext extends AppContext {
  recoil: CherreCallbackInterface;
}

export type EventWithRecoil<Params extends Array<any>, R> = (
  context: EventWithRecoilContext
) => (...params: Params) => R | Promise<R>;

export const useCherreEventWithRecoil = <P, Params extends P[], R>(
  name: string,
  event?: EventWithRecoil<Params, R>,
  options: EventOptions<R, CallbackRecoilContext> = {}
): ((...params: Params) => void | Promise<void>) => {
  const scope = useContext(recoilScopeContext);
  const scopeRef = useRef(scope);
  scopeRef.current = scope;
  const run = useRecoilCallback(
    ({ set, reset, refresh, snapshot }) =>
      async <T>(cbk: (recoil: CherreCallbackInterface) => T) => {
        const release = snapshot.retain();
        const resNode = resNodeFactory(scopeRef.current);
        try {
          const result = cbk({
            set: (node, value: any) => set(resNode(node), value),
            reset: (node) => reset(resNode(node)),
            refresh: (node) => refresh(resNode(node)),
            getPromise: (node) => {
              triggerSubscription(resNode(node));
              return snapshot.getPromise(resNode(node));
            },
          });
          if (result instanceof Promise) {
            return await result;
          } else {
            return result;
          }
        } finally {
          release();
        }
      },
    []
  );
  return useCherreEventWithFlow(
    name,
    event
      ? (ctx) =>
          async (...params) =>
            run((recoil) => event({ ...ctx, recoil })(...params))
      : undefined,
    {
      ...options,
      onCompleted: options.onCompleted
        ? async (value, ctx) =>
            run((recoil) =>
              options.onCompleted?.(value, {
                ...ctx,
                recoil,
              })
            )
        : undefined,
      onError: options.onError
        ? async (value, ctx) =>
            run((recoil) =>
              options.onError?.(value, {
                ...ctx,
                recoil,
              })
            )
        : undefined,
      onTimeout: options.onTimeout
        ? async (value, ctx) =>
            run((recoil) =>
              options.onTimeout?.(value, {
                ...ctx,
                recoil,
              })
            )
        : undefined,
      onCancelled: options.onCancelled
        ? async (value, ctx) =>
            run((recoil) =>
              options.onCancelled?.(value, {
                ...ctx,
                recoil,
              })
            )
        : undefined,
    }
  );
};
