import React from 'react';
import { RecoilScope } from './RecoilScope';

export const RelayEnvironmentScope: React.FC<{ environment: string }> = ({
  environment,
  children,
}) => {
  return (
    <RecoilScope scope={{ relayEnvironment: environment }}>
      {children}
    </RecoilScope>
  );
};
