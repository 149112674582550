import React, { useEffect, useState } from 'react';
import { EnvironmentStore } from '../recoil/types';

type EnvironmentManagerProps<T> = {
  store: EnvironmentStore<T>;
  defaultEnvironment: () => Promise<T>;
  environments?: Record<string, () => Promise<T>>;
  children: React.ReactNode;
};

export const EnvironmentManager_INTERNAL = <T,>(
  props: EnvironmentManagerProps<T>
) => {
  const [registered, setRegistered] = useState(false);
  useEffect(() => {
    props.store.registerDefaultEnvironment(props.defaultEnvironment);
    if (props.environments) {
      props.store.registerEnvironments(props.environments);
    }
    setRegistered(true);
    return () => {
      props.store.unregisterDefaultEnvironment();
      if (props.environments) {
        props.store.unregisterEnvironments(props.environments);
      }
    };
  }, [props.store, props.defaultEnvironment, props.environments]);
  return registered ? <>{props.children}</> : null;
};
