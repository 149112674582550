import { SetterOrUpdater } from 'recoil';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useCherreState } from './useCherreState';
import { CherreState } from '../recoil';

type SetOrUpdateParams<T> = Parameters<SetterOrUpdater<T>>[0];

type UseCherreStateDebouncedOptions = {
  ms?: number;
};

export const useCherreStateDebounced = <T>(
  node: CherreState<T>,
  options?: UseCherreStateDebouncedOptions
) => {
  const [nodeValue, setNodeValue] = useCherreState(node);
  const [localValue, setLocalValue] = useState(nodeValue);

  //sync local with node
  useEffect(() => {
    setLocalValue(nodeValue);
  }, [nodeValue]);

  const setNodeValueDebounced = useCallback(
    debounce(
      (params: SetOrUpdateParams<T>) => setNodeValue(params),
      options?.ms || 500
    ),
    [setNodeValue, options?.ms]
  );

  useEffect(() => {
    return () => {
      setNodeValueDebounced.cancel();
    };
  }, [setNodeValueDebounced]);

  const setValue = useCallback(
    (params: SetOrUpdateParams<T>) => {
      setLocalValue(params as SetStateAction<T | undefined>);
      setNodeValueDebounced(params);
    },
    [setLocalValue, setNodeValueDebounced]
  );

  return [localValue, nodeValue, setValue] as const;
};
