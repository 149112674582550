import { AtomEffect } from 'recoil';
import { subscriptionEffect } from './subscriptionEffect';

type Resetter = () => void;

const subscriptions = new Map<string, number>();
const resets = new Map<string, Set<Resetter>>();
const resetTimeout = new Map<string, NodeJS.Timeout>();

const addReset = (key: string, reset: Resetter) => {
  const resetSet = resets.get(key) || new Set();
  resetSet.add(reset);
  resets.set(key, resetSet);
};

const resetAll = (key: string) => {
  resets.get(key)?.forEach((r) => r());
};

const clearResetTimeout = (key: string) => {
  clearTimeout(resetTimeout.get(key));
};

const setResetTimeout = (
  key: string,
  onTimeout?: () => void,
  timeoutMs = 100
) => {
  resetTimeout.set(
    key,
    setTimeout(() => {
      resetAll(key);
      onTimeout?.();
    }, timeoutMs)
  );
};

const subscribe = (key: string) => {
  clearResetTimeout(key);
  const count = subscriptions.get(key) || 0;
  subscriptions.set(key, count + 1);
};

const unsubscribe = (
  key: string,
  onTimeout?: () => void,
  timeoutMs?: number
) => {
  const count = subscriptions.get(key) || 0;
  subscriptions.set(key, Math.max(0, count - 1));
  if (count < 2) {
    setResetTimeout(key, onTimeout, timeoutMs);
  }
};

export type ResetCacheEffectOptions = {
  /**
   * The key to use for the cache. If not provided, the atom's key will be used.
   */
  key?: string;
  /**
   * A callback to be called when the cache is reset.
   */
  resetCallback?: () => void;
  /**
   * The timeout in milliseconds to wait before resetting the cache.
   */
  timeoutMs?: number;
};

/**
 * Resets the cache when the number of subscribers to the atom reaches 0.
 */
export const resetCacheEffect =
  <T>(options: ResetCacheEffectOptions): AtomEffect<T> =>
  (params) => {
    const key = options.key || params.node.key;
    addReset(key, params.resetSelf);
    return subscriptionEffect<T>(() => {
      subscribe(key);
      return () => unsubscribe(key, options.resetCallback, options.timeoutMs);
    }, false)(params);
  };
