import React from 'react';
import { useGetRecoilValueInfo_UNSTABLE } from 'recoil';
import { updateNodes_INTERNAL } from '../recoil/subscriptionStore_INTERNAL';

export const RecoilSubscriptionManager_INTERNAL: React.FC = () => {
  const getInfo = useGetRecoilValueInfo_UNSTABLE();
  React.useEffect(() => {
    const intervalHandle = setInterval(
      () => updateNodes_INTERNAL(getInfo),
      1_000
    );
    return () => {
      clearInterval(intervalHandle);
    };
  }, []);

  return null;
};
