import { Loadable, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useIsSuspended } from './useIsSuspended';
import { DataFetchingError } from '../classes/error';
import { useAppContext } from '@cherre-frontend/core';
import { CherreLoadingValue } from '../recoil/core/CherreLoadingValue';
import { CherreSwrController } from '../recoil/core/CherreSwrController';
import { triggerSubscription } from '../recoil/subscriptionStore_INTERNAL';
import { CherreValue } from '../recoil';
import { useResolveNode } from './useRecoilScope';

export const useCherreValue = <T>(
  nodeOrScopedNode: CherreValue<T>
): T | undefined => {
  const { logger } = useAppContext();

  const node = useResolveNode(nodeOrScopedNode);
  try {
    const suspended = useIsSuspended();

    triggerSubscription(node);

    if (!suspended) {
      const value = useRecoilValue(node);
      if (value instanceof CherreLoadingValue) {
        throw value.getPromise();
      }
      return value;
    }
    const value = useRecoilValueLoadable(node).valueMaybe();
    const swrValue = CherreSwrController.getSwrValueForNode(node);
    if (value && !(value instanceof CherreLoadingValue)) {
      return value;
    }
    return swrValue;
  } catch (errorOrPromise) {
    if (errorOrPromise instanceof Promise) {
      logger.info(`suspending due to ${node.key}`);
      throw errorOrPromise;
    }
    throw new DataFetchingError(node, errorOrPromise);
  }
};

export const useCherreValueLoadable = <T>(
  nodeOrScopedNode: CherreValue<T>
): Loadable<T> => {
  const { logger } = useAppContext();

  const node = useResolveNode(nodeOrScopedNode);
  try {
    triggerSubscription(node);

    return useRecoilValueLoadable(node);
  } catch (errorOrPromise) {
    if (errorOrPromise instanceof Promise) {
      logger.info(`suspending due to ${node.key}`);
      throw errorOrPromise;
    }
    throw new DataFetchingError(node, errorOrPromise);
  }
};
