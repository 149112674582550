import { AtomEffect, RecoilState } from 'recoil';
import { Checker, mixed } from '@recoiljs/refine';
import { v4 as uuid } from 'uuid';
import { FetchError, RefinementError } from '../classes/error';
import { AxiosEnvironmentStore_INTERNAL } from './axiosEnvironmentStore_INTERNAL';
import { isAxiosError } from 'axios';
import { InternalValue, asyncAtomFamily } from './core/asyncAtomFamily';
import { refetchEffect } from './core/refetchEffect';
import { resetCacheEffect } from './core/resetCacheEffect';

const checkerStore = new Map<string, Checker<unknown>>();

const getKey = (url: string, environment = 'default') =>
  `${environment}::${url}`;

const getChecker = (url: string, environment?: string) => {
  return checkerStore.get(getKey(url, environment)) || mixed();
};

const setChecker = (
  url: string,
  checker: Checker<unknown>,
  environment?: string
) => {
  checkerStore.set(getKey(url, environment), checker);
};

type GetSelectorFamilyParams = {
  url: string;
  environment?: string;
  resetCache: boolean;
};

const getAtomFamily = asyncAtomFamily({
  key: '@cherre-frontend/data-fetching/axios-get-atom',
  resetCache: false,
  swr: (oldV, newV) =>
    oldV.url === newV.url && oldV.environment === newV.environment,
  effects: ({ url, environment, resetCache }: GetSelectorFamilyParams) =>
    [
      refetchEffect<InternalValue<unknown>>((effectParams) => {
        const axiosPromise =
          AxiosEnvironmentStore_INTERNAL.getEnvironment(environment);
        const cherreRequestId = uuid();
        axiosPromise.then((axios) =>
          axios
            .get(url, { headers: { cherreRequestId } })
            .then((result) => {
              const checker = getChecker(url, environment);
              const checkResult = checker(result.data);
              if (checkResult.type === 'failure') {
                throw new RefinementError(checkResult);
              }
              effectParams.setSelf({ result: checkResult.value });
            })
            .catch((e) => {
              let error = e;
              if (isAxiosError(e)) {
                error = new FetchError(url, cherreRequestId, error);
              }
              effectParams.setSelf({ error });
            })
        );
      }),
      resetCache
        ? resetCacheEffect({
            key: getKey(url, environment),
          })
        : undefined,
    ].filter(Boolean) as AtomEffect<InternalValue<unknown>>[],
});

export type GetSelectorParams<T> = {
  url: string;
  checker?: Checker<T>;
  environment?: string;
  resetCache?: boolean;
};

export const getSelector = <T>({
  url,
  checker,
  environment,
  resetCache = true,
}: GetSelectorParams<T>) => {
  if (checker) {
    setChecker(url, checker, environment);
  }
  return getAtomFamily({
    url,
    environment,
    resetCache,
  }) as RecoilState<T>;
};
