import { EventOptions } from '@cherre-frontend/core';
import { Checker, mixed } from '@recoiljs/refine';
import { AxiosEnvironmentStore_INTERNAL } from '../recoil/axiosEnvironmentStore_INTERNAL';
import { v4 as uuid } from 'uuid';
import { FetchError, RefinementError } from '../classes/error';
import { AxiosRequestConfig, isAxiosError } from 'axios';
import {
  useCherreEventWithRecoil,
  CallbackRecoilContext,
} from './useCherreEventWithRecoil';

export type UsePostOptions<V, R> = EventOptions<R, CallbackRecoilContext> & {
  environment?: string;
  bodyChecker?: Checker<V>;
  responseChecker?: Checker<R>;
  axiosOptions?: AxiosRequestConfig;
};

export const usePost = <V, R>(
  urlOrName: string,
  options?: UsePostOptions<V, R>
) => {
  return useCherreEventWithRecoil(
    `POST::${urlOrName}`,
    (context) => async (body: V, url?: string) => {
      const axios = await AxiosEnvironmentStore_INTERNAL.getEnvironment(
        options?.environment
      );
      const requestUrl = url || urlOrName;
      const cherreRequestId = uuid();
      try {
        const bodyChecker = options?.bodyChecker || mixed();
        const bodyCheckResult = bodyChecker(body);
        if (bodyCheckResult.type === 'failure') {
          throw new RefinementError(bodyCheckResult);
        }
        const requestBody = bodyCheckResult.value;
        const response = await axios.post(requestUrl, requestBody, {
          ...options?.axiosOptions,
          headers: { cherreRequestId, ...options?.axiosOptions?.headers },
          signal: context.abortSignal,
        });
        const responseChecker = options?.responseChecker || mixed();
        const responseCheckResult = responseChecker(response.data);
        if (responseCheckResult.type === 'failure') {
          throw new RefinementError(responseCheckResult);
        }
        return responseCheckResult.value as R;
      } catch (error) {
        if (isAxiosError(error)) {
          throw new FetchError(requestUrl, cherreRequestId, error);
        }
        throw error;
      }
    },
    options
  );
};
