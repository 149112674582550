export type Resolver<T = any> = (value: T) => void;
export type Rejecter = (error: unknown) => void;

const promiseMap = new Map<CherreLoadingValue, Promise<any>>();
const resolveMap = new Map<CherreLoadingValue, Resolver>();
const rejectMap = new Map<CherreLoadingValue, Rejecter>();

export class CherreLoadingValue<T = any> {
  private get promise(): Promise<T> {
    const maybePromise = promiseMap.get(this);
    if (!maybePromise) {
      throw new Error('Promise not set');
    }
    return maybePromise;
  }

  private set promise(newPromise: Promise<T>) {
    promiseMap.set(this, newPromise);
  }

  private get resolve() {
    return resolveMap.get(this) || null;
  }

  private set resolve(newResolve: Resolver<T> | null) {
    if (newResolve === null) {
      resolveMap.delete(this);
    } else {
      resolveMap.set(this, newResolve);
    }
  }

  private get reject() {
    return rejectMap.get(this) || null;
  }

  private set reject(newReject: Rejecter | null) {
    if (newReject === null) {
      rejectMap.delete(this);
    } else {
      rejectMap.set(this, newReject);
    }
  }

  constructor() {
    this.createPromise();
  }

  private createPromise() {
    this.promise = new Promise((res, rej) => {
      this.resolve = res;
      this.reject = rej;
    });
  }

  /**
   * Resolves the promise and creates a new one.
   */
  public resolvePromise(value: T) {
    this.resolve?.(value);
    this.createPromise();
  }

  /**
   * Rejects the promise and creates a new one.
   */
  public rejectPromise(error: unknown) {
    this.reject?.(error);
    this.createPromise();
  }

  /**
   * Returns the promise.
   */
  public getPromise(): Promise<T> {
    return this.promise;
  }
}
