import {
  ConcreteRequest,
  GraphQLTaggedNode,
  OperationType,
  fetchQuery,
  requestSubscription,
} from 'relay-runtime';
import { subscriptionEffect } from './core/subscriptionEffect';
import { RelayEnvironmentStore_INTERNAL } from './relayEnvironmentStore_INTERNAL';
import { refetchEffect } from './core/refetchEffect';
import {
  AsyncAtomFamilyPossibleOptions,
  AsyncAtomFamilyPossibleReturns,
  V_Inputs,
  asyncAtomFamily,
} from './core/asyncAtomFamily';
import {
  CherreValue,
  GetCherreValue,
  ScopedNodeFamily,
  isScopedNode,
  transformNodeFamilyIntoScopedNodeFamily,
} from './core/scopedNodes';

export type Writeable<T> = { -readonly [P in keyof T]: Writeable<T[P]> };

export type GraphQLReturn<Q extends OperationType> = {
  default: GraphQLTaggedNode & ConcreteRequest & Q;
};

export type QueryResult<T> = T extends (
  ...inputs: any[]
) => CherreValue<infer R>
  ? R
  : never;

type GraphQLSelectorOptionsBase<Q extends OperationType> = {
  query: GraphQLReturn<Q>;
  polling?: true | number;
  environment?: string;
};

type GraphQLSelectorPossibleOptions<
  Q extends OperationType,
  MR,
  Vs extends V_Inputs
> = {
  [K in keyof AsyncAtomFamilyPossibleOptions<
    Q['response'],
    MR,
    Vs,
    Q['variables'],
    GetCherreValue
  >]: GraphQLSelectorOptionsBase<Q> &
    Omit<
      AsyncAtomFamilyPossibleOptions<
        Writeable<Q['response']>,
        MR,
        Vs,
        Q['variables'],
        GetCherreValue
      >[K],
      'key'
    >;
};

type GraphQLSelectorPossibleReturns<
  Q extends OperationType,
  MR,
  Vs extends V_Inputs
> = AsyncAtomFamilyPossibleReturns<Q['response'], MR, Vs, Q['variables']>;

export function graphQLSelector<
  Q extends OperationType,
  MR,
  Vs extends V_Inputs
>(
  options: GraphQLSelectorPossibleOptions<Q, MR, Vs>['withoutMRwithoutMV']
): ScopedNodeFamily<
  GraphQLSelectorPossibleReturns<Q, MR, Vs>['withoutMRwithoutMV']
>;

export function graphQLSelector<
  Q extends OperationType,
  MR,
  Vs extends V_Inputs
>(
  options: GraphQLSelectorPossibleOptions<Q, MR, Vs>['withoutMRwithMV']
): ScopedNodeFamily<
  GraphQLSelectorPossibleReturns<Q, MR, Vs>['withoutMRwithMV']
>;

export function graphQLSelector<
  Q extends OperationType,
  MR,
  Vs extends V_Inputs
>(
  options: GraphQLSelectorPossibleOptions<Q, MR, Vs>['withMRwithoutMV']
): ScopedNodeFamily<
  GraphQLSelectorPossibleReturns<Q, MR, Vs>['withMRwithoutMV']
>;

export function graphQLSelector<
  Q extends OperationType,
  MR,
  Vs extends V_Inputs
>(
  options: GraphQLSelectorPossibleOptions<Q, MR, Vs>['withMRwithMV']
): ScopedNodeFamily<GraphQLSelectorPossibleReturns<Q, MR, Vs>['withMRwithMV']>;

export function graphQLSelector(params) {
  const query = params.query.default;
  const operationKind = query.params.operationKind;
  const operationName = query.fragment.name;
  const polling_enabled = Boolean(params.polling);
  const polling_interval: number =
    typeof params.polling === 'number' ? params.polling : 5_000;
  const key = `GraphQLSelector::${operationKind}::${operationName}`;
  const mapVariables =
    (v) =>
    ({ get }) => {
      if (params.mapVariables) {
        return {
          scope: v.scope,
          params: params.mapVariables(v.params)({
            get: <T>(node: CherreValue<T>) => {
              if (isScopedNode(node)) {
                return get(node(v.scope));
              }
              return get(node);
            },
          }),
        };
      }
      return v;
    };
  const mapResponse = params.mapResponse
    ? (d, v) => params.mapResponse(d, v.params)
    : undefined;
  const innerFamily = asyncAtomFamily({
    ...params,
    mapVariables,
    mapResponse,
    key: `Async(${key})`,
    effects: ({ scope, params }: any) => [
      operationKind === 'query'
        ? polling_enabled
          ? subscriptionEffect((effectParams) => {
              let mounted = true;
              const update = () => {
                RelayEnvironmentStore_INTERNAL.getEnvironment(
                  scope['relayEnvironment'] || params.environment
                ).then((env) => {
                  return fetchQuery(env, query, params, {
                    fetchPolicy: 'network-only',
                  })
                    .toPromise()
                    .then((result) => effectParams.setSelf({ result }))
                    .catch((error) => effectParams.setSelf({ error }))
                    .finally(() => {
                      if (mounted) {
                        setTimeout(update, polling_interval);
                      }
                    });
                });
              };
              update();
              return () => {
                mounted = false;
              };
            })
          : refetchEffect((effectParams) => {
              RelayEnvironmentStore_INTERNAL.getEnvironment(
                scope['relayEnvironment'] || params.environment
              ).then((env) => {
                return fetchQuery(env, query, params, {
                  fetchPolicy: 'network-only',
                })
                  .toPromise()
                  .then((result) => effectParams.setSelf({ result }))
                  .catch((error) => effectParams.setSelf({ error }));
              });
            })
        : subscriptionEffect((effectParams) => {
            let dispose: (() => void) | null = null;
            RelayEnvironmentStore_INTERNAL.getEnvironment(
              scope['relayEnvironment'] || params.environment
            ).then((env) => {
              const subs = requestSubscription(env, {
                subscription: query,
                variables: params,
                onNext: (result) => effectParams.setSelf({ result }),
                onError: (error) => effectParams.setSelf({ error }),
              });
              dispose = subs.dispose;
            });
            return () => dispose?.();
          }),
    ],
  });
  return transformNodeFamilyIntoScopedNodeFamily(innerFamily);
}
