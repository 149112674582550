import React, { useContext } from 'react';
import { recoilScopeContext } from '../contexts/recoilScopeContext';

export type RecoilScopeProps = {
  scope: Record<string, string>;
};

export const RecoilScope: React.FC<RecoilScopeProps> = ({
  scope,
  children,
}) => {
  const parentScope = useContext(recoilScopeContext);
  return (
    <recoilScopeContext.Provider value={{ ...parentScope, ...scope }}>
      {children}
    </recoilScopeContext.Provider>
  );
};
