import React from 'react';
import { RecoilValue } from 'recoil';
import { ErrorBoundaryBehaviour } from '../components/ErrorBoundary_INTERNAL';

type ErrorBoundaryCommonProps = {
  error: unknown;
  clearError: () => void;
  node?: RecoilValue<unknown>;
  styles?: React.CSSProperties;
  className?: string;
};

interface ErrorBoundaryPropsWithRetry extends ErrorBoundaryCommonProps {
  onRetry: () => void;
  showRetryButton: true;
}

interface ErrorBoundaryPropsWithoutRetry extends ErrorBoundaryCommonProps {
  showRetryButton: false;
}

export type ErrorBoundaryProps =
  | ErrorBoundaryPropsWithRetry
  | ErrorBoundaryPropsWithoutRetry;

export type ErrorBoundaryComponent = React.FC<ErrorBoundaryProps>;

export type SuspenseWrapperProps = {
  styles?: React.CSSProperties;
  className?: string;
};

export type SuspenseWrapperComponent = React.FC<SuspenseWrapperProps>;

export type ResumeWrapperProps = {
  styles?: React.CSSProperties;
  className?: string;
};

export type ResumeWrapperComponent = React.FC<ResumeWrapperProps>;

export type DataFetchingConfig = {
  defaultErrorBehavior: ErrorBoundaryBehaviour;
  defaultErrorBoundary: ErrorBoundaryComponent;
  suspenseWrapper: SuspenseWrapperComponent;
  //adds a resume wrapper so we can have simmetry between states
  //this avoids dealing with adding and removing divs
  //resumewrapper should be identical to suspensewrapper except for the skeleton backaground animation
  resumeWrapper: ResumeWrapperComponent;
};

export const dataFetchingConfigContext_INTERNAL =
  React.createContext<DataFetchingConfig | null>(null);
