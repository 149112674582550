import { RecoilValue, SerializableParam } from 'recoil';
import { ScopeStore, ScopedNode } from './scopedNodes';

type RecoilNodeFamily<T, P extends SerializableParam> = (
  params: P
) => RecoilValue<T>;

type ScopedNodeFamily<T, P extends SerializableParam> = (
  params: P
) => ScopedNode<RecoilValue<T>>;

export class CherreSwrController<T, P extends SerializableParam> {
  static store = new Map<string, any>();

  public static getSwrValueForNode<T>(node: RecoilValue<T>): T | undefined {
    const swrValue = CherreSwrController.store.get(node.key);
    if (!swrValue) {
      return undefined;
    }
    return swrValue;
  }

  private callstack: P[] = [];
  private valueList = new Map<string, T>();
  private nodeFamily: RecoilNodeFamily<T, P> | null = null;
  private scopedNodeFamily: ScopedNodeFamily<T, P> | null = null;
  private swrPredicate: (oldV: P, newV: P) => boolean = () => true;

  private getSwrValue(params: P): T | undefined {
    const nodeFamily = this.nodeFamily;
    if (!nodeFamily) {
      throw new Error(`nodeFamily not set`);
    }
    const matchingParams = this.callstack.filter((oldP) =>
      this.swrPredicate(oldP, params)
    );
    const lastParamSet = matchingParams.find((param) =>
      this.valueList.has(nodeFamily(param).key)
    );
    if (!lastParamSet) {
      return undefined;
    }
    return this.valueList.get(nodeFamily(lastParamSet).key);
  }

  private getScopedSwrValue(params: P, scope: ScopeStore): T | undefined {
    const scopedNodeFamily = this.scopedNodeFamily;
    if (!scopedNodeFamily) {
      throw new Error(`scopedNodeFamily not set`);
    }
    const matchingParams = this.callstack.filter((oldP) =>
      this.swrPredicate(oldP, params)
    );
    const lastParamSet = matchingParams.find((param) =>
      this.valueList.has(scopedNodeFamily(param)(scope).key)
    );
    if (!lastParamSet) {
      return undefined;
    }
    return this.valueList.get(scopedNodeFamily(lastParamSet)(scope).key);
  }

  public setNodeFamily(nodeFamily: RecoilNodeFamily<T, P>) {
    this.nodeFamily = nodeFamily;
  }

  public setScopedNodeFamily(scopedNodeFamily: ScopedNodeFamily<T, P>) {
    this.scopedNodeFamily = scopedNodeFamily;
  }

  public setSwrPredicate(swrPredicate?: (oldV: P, newV: P) => boolean) {
    if (swrPredicate) {
      this.swrPredicate = swrPredicate;
    }
  }

  public setNodeSet(node: RecoilValue<T>, value: T) {
    this.valueList.set(node.key, value);
  }

  public resetSwr() {
    this.callstack = [];
    Array.from(this.valueList.keys()).forEach((key) =>
      CherreSwrController.store.delete(key)
    );
    this.valueList.clear();
  }

  public familyStub(): RecoilNodeFamily<T, P> {
    return (params) => {
      if (!this.nodeFamily) {
        throw new Error(`nodeFamily not set`);
      }
      const node = this.nodeFamily(params);
      const swrValue = this.getSwrValue(params);
      CherreSwrController.store.set(node.key, swrValue);
      this.callstack.unshift(params);
      return node;
    };
  }

  public scopedFamilyStub(): ScopedNodeFamily<T, P> {
    return (params) => {
      const scopedNodeFamily = this.scopedNodeFamily;
      if (!scopedNodeFamily) {
        throw new Error(`scopedNodeFamily not set`);
      }
      const scopedNode: ScopedNode<RecoilValue<T>> = (scope) => {
        const node = scopedNodeFamily(params)(scope);
        const swrValue = this.getScopedSwrValue(params, scope);
        CherreSwrController.store.set(node.key, swrValue);
        this.callstack.unshift(params);
        return node;
      };
      scopedNode.key = scopedNodeFamily(params)({}).key;
      scopedNode.$$type = 'ScopedNode';
      return scopedNode;
    };
  }
}
