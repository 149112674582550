import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

type UseStateDebouncedOptions = {
  ms?: number;
  noLocalValueUpdate?: boolean;
};

export const useStateDebounced = <T>(
  initialValue: T,
  options?: UseStateDebouncedOptions
) => {
  const [nodeValue, setNodeValue] = useState(initialValue);
  const [localValue, setLocalValue] = useState(nodeValue);

  const setNodeValueDebounced = useCallback(
    debounce<typeof setNodeValue>(
      (params) => setNodeValue(params),
      options?.ms || 500
    ),
    [setNodeValue, options?.ms]
  );

  useEffect(() => {
    return () => {
      setNodeValueDebounced.cancel();
    };
  }, [setNodeValueDebounced]);

  const setValue = useCallback<typeof setNodeValue>(
    (params) => {
      if (!options?.noLocalValueUpdate) {
        setLocalValue(params);
      }
      setNodeValueDebounced(params);
    },
    [setLocalValue, setNodeValueDebounced]
  );

  return [localValue, nodeValue, setValue] as const;
};
