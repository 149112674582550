import { useCallback, useMemo, useState } from 'react';
import { ZodType } from 'zod';

export const useForm = <T>(
  schema: ZodType<T>,
  initialState: Partial<T> = {}
) => {
  const [formState, setFormState] = useState<Partial<T>>(initialState);

  const setPartialFormState = useCallback(
    (partial: Partial<T>) => setFormState((old) => ({ ...old, ...partial })),
    []
  );

  const result = useMemo(
    () => schema.safeParse(formState),
    [formState, schema]
  );

  return [formState, setPartialFormState, result] as const;
};
