import { AtomEffect } from 'recoil';
import { CherreLoadingValue } from './CherreLoadingValue';

/**
 * This is used to mitigate recoil's annoying behavior
 * when you don't specify a default value it uses a promise that never resolves as the default value
 * if you reset the atom at any moment it will reuse the same promise and thus the component will keep waiting for ever in suspense
 * if you specify `defaultValue: new CherreLoadingValue()` and use the loading effect
 * it will unwrap the internal promise and automatically resolve it whenever the atom is setted again
 * @returns
 */
export const loadingEffect =
  <T>(): AtomEffect<T | CherreLoadingValue<T>> =>
  (params) => {
    const nodeLoadable = params.getLoadable(params.node);
    if (nodeLoadable.state === 'loading') {
      throw new Error(
        `loading Effect requires the atom to have a default value`
      );
    }
    const loadingPromise = nodeLoadable.getValue();
    if (loadingPromise instanceof CherreLoadingValue) {
      loadingPromise.getPromise().then((result) => params.setSelf(result));
    }
    params.onSet((newValue, oldValue, isReset) => {
      if (isReset && newValue instanceof CherreLoadingValue) {
        newValue.getPromise().then((result) => params.setSelf(result));
        return;
      }
      if (!isReset && oldValue instanceof CherreLoadingValue) {
        if (newValue instanceof CherreLoadingValue) {
          newValue.getPromise().then(oldValue.resolvePromise);
        } else {
          oldValue.resolvePromise(newValue);
        }
      }
    });
  };
