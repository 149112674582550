import { AxiosInstance } from 'axios';
import { EnvironmentStore } from './types';

const DEFAULT_KEY = '@cherre-frontend/data-fetching/default-axios-environment';

const environmentStore = new Map<string, Promise<AxiosInstance>>();
const environmentFactoryStore = new Map<string, () => Promise<AxiosInstance>>();

const getDefaultEnvironment = () => {
  const defaultEnvironment = environmentStore.get(DEFAULT_KEY);
  if (!defaultEnvironment) {
    const defaultFactory = environmentFactoryStore.get(DEFAULT_KEY);
    if (!defaultFactory) {
      throw new Error(`
      default Axios environment is not set, which means that DataFetchingProvider is not mounted or does not exist,
      make sure DataFetchingProvider is on the Root of the application, and has a defaultAxiosEnvironment.
      `);
    }
    const promise = defaultFactory();
    environmentStore.set(DEFAULT_KEY, promise);
    return promise;
  }
  return defaultEnvironment;
};

const assertEnvironment = (key: string) => {
  const environment = environmentStore.get(key);
  if (!environment) {
    const factory = environmentFactoryStore.get(key);
    if (!factory) {
      throw new Error(`
      Axios environment for key ${key} is not set,
      make sure to pass a valid Axios environment with this key to DataFetchingProvider
      `);
    }
    const promise = factory();
    environmentStore.set(key, promise);
    return promise;
  }
  return environment;
};

const getEnvironment = (key?: string) => {
  if (key) {
    return assertEnvironment(key);
  }
  return getDefaultEnvironment();
};

const registerEnvironment = (
  key: string,
  env: () => Promise<AxiosInstance>
) => {
  if (environmentFactoryStore.has(key)) {
    throw new Error(`Axios environment for key ${key} is already registered`);
  }
  environmentFactoryStore.set(key, env);
};

const registerEnvironments = (
  envs: Record<string, () => Promise<AxiosInstance>>
) => {
  Object.entries(envs).forEach(([k, v]) => registerEnvironment(k, v));
};

const unregisterEnvironment = (key: string) => {
  if (!environmentFactoryStore.delete(key)) {
    throw new Error(`Axios environment for key ${key} is not registered`);
  }
  environmentStore.delete(key);
};

const unregisterEnvironments = (
  envs: Record<string, () => Promise<AxiosInstance>>
) => {
  Object.keys(envs).forEach((k) => unregisterEnvironment(k));
};

const registerDefaultEnvironment = (env: () => Promise<AxiosInstance>) => {
  if (environmentFactoryStore.has(DEFAULT_KEY)) {
    throw new Error(`Axios default environment is already registered`);
  }
  environmentFactoryStore.set(DEFAULT_KEY, env);
};

const unregisterDefaultEnvironment = () => {
  if (!environmentFactoryStore.delete(DEFAULT_KEY)) {
    throw new Error(`Axios default environment is not registered`);
  }
  environmentStore.delete(DEFAULT_KEY);
};

export const AxiosEnvironmentStore_INTERNAL: EnvironmentStore<AxiosInstance> = {
  getEnvironment,
  registerEnvironment,
  registerEnvironments,
  unregisterEnvironment,
  unregisterEnvironments,
  registerDefaultEnvironment,
  unregisterDefaultEnvironment,
};
