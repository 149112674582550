import { AtomEffect } from 'recoil';
import { subscriptionEffect } from './subscriptionEffect';

type FetchFn<T> = (params: { setSelf: (value: T) => void }) => void;

export const refetchEffect =
  <T>(fetcher: FetchFn<T>): AtomEffect<T> =>
  (params) => {
    let isMounted = true;
    let isReset = true;
    let resolve: ((newValue: T) => void) | null = null;
    params.setSelf(new Promise((res) => (resolve = res)));
    const setSelf: typeof params.setSelf = (newValue) => {
      if (newValue instanceof Promise) {
        return;
      }
      isReset = false;
      params.setSelf(newValue);
      resolve?.(newValue as any);
      resolve = null;
    };
    const refetch = () => {
      if (!isMounted || !isReset) {
        return;
      }
      fetcher({ setSelf });
    };
    params.onSet((_, __, reset) => {
      isReset = reset;
      refetch();
    });
    return subscriptionEffect<T>(() => {
      isMounted = true;
      refetch();
      return () => {
        isMounted = false;
      };
    }, false)(params);
  };
