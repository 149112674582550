import { RecoilValue, useGetRecoilValueInfo_UNSTABLE } from 'recoil';

const subscriptionStore = new Set<RecoilValue<any>>();
const subscriptions = new Map<RecoilValue<any>, Set<() => void>>();
const unsubscriptions = new Map<RecoilValue<any>, Set<() => void>>();
const subscriptionPairs = new Map<RecoilValue<any>, RecoilValue<any>>();

export const addToStore_INTERNAL = (
  node: RecoilValue<any>,
  subscribe: () => void,
  unsubscribe: () => void
) => {
  subscriptionStore.add(node);
  const subscriptionSet = subscriptions.get(node) || new Set();
  subscriptionSet.add(subscribe);
  subscriptions.set(node, subscriptionSet);
  const unsubscriptionSet = unsubscriptions.get(node) || new Set();
  unsubscriptionSet.add(unsubscribe);
  unsubscriptions.set(node, unsubscriptionSet);
};

export const removeFromStore_INTERNAL = (
  node: RecoilValue<any>,
  subscribe: () => void,
  unsubscribe: () => void
) => {
  subscriptions.get(node)?.delete(subscribe);
  unsubscriptions.get(node)?.delete(unsubscribe);
};

type GetInfo = ReturnType<typeof useGetRecoilValueInfo_UNSTABLE>;

// checks if there are any UI components that are listening to this piece of state
const hasUISubscribers = (
  recoilValue: RecoilValue<any>,
  getInfo: GetInfo
): boolean => {
  const { subscribers } = getInfo(recoilValue);
  const subscribedComponents = Array.from(subscribers.components).length;
  //since we are just checking if there are UI subscribers, we dont need to get the actual number
  //if there is at least one we can return here
  if (subscribedComponents > 0) {
    return true;
  }
  //return true if some node subscriber have UI subscribers
  const mySubscribedNodes = Array.from(subscribers.nodes);
  return mySubscribedNodes.some((node) => hasUISubscribers(node, getInfo));
};

export const triggerSubscription = (node: RecoilValue<any>) => {
  subscriptions.get(node)?.forEach((s) => s());
  const pair = subscriptionPairs.get(node);
  if (pair) {
    subscriptions.get(pair)?.forEach((s) => s());
  }
};

export const triggerUnsubscription = (node: RecoilValue<any>) => {
  unsubscriptions.get(node)?.forEach((s) => s());
  const pair = subscriptionPairs.get(node);
  if (pair) {
    unsubscriptions.get(pair)?.forEach((s) => s());
  }
};

export const updateNodes_INTERNAL = (getInfo: GetInfo) => {
  for (const node of subscriptionStore) {
    // it there are UI components listening to it, we should subscribe for changes
    // otherwise we can turn off the node
    if (hasUISubscribers(node, getInfo)) {
      triggerSubscription(node);
    } else {
      triggerUnsubscription(node);
    }
  }
};

export const setSubscriptionPair = (
  primaryNode: RecoilValue<any>,
  secondaryNode: RecoilValue<any>
) => {
  subscriptionPairs.set(secondaryNode, primaryNode);
};
