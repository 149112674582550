import {
  Checker,
  CheckerReturnType,
  custom,
  object,
  string,
  voidable,
} from '@recoiljs/refine';
import { RecoilState, atom, selector, selectorFamily } from 'recoil';
import { urlSyncEffect } from 'recoil-sync';
import { Route, RouteParams, RouteParamsReturnType } from './types';
import { matchPath } from 'react-router';
import { dependencyStore } from './dependantsEffect';
import { isEqual } from 'lodash';

const RECOIL_ROUTER_STORE_KEY_INTERNAL = 'RECOIL-ROUTER-SYNC-STORE';

const refineLocation = object({
  pathname: string(),
  search: string(),
  hash: string(),
});

export const hrefAtom_INTERNAL = atom({
  key: `${RECOIL_ROUTER_STORE_KEY_INTERNAL}-ATOM`,
  effects: [
    urlSyncEffect({
      storeKey: RECOIL_ROUTER_STORE_KEY_INTERNAL,
      refine: refineLocation,
      itemKey: 'root',
    }),
  ],
});

export const matchCurrentPathname = selectorFamily({
  key: `${RECOIL_ROUTER_STORE_KEY_INTERNAL}-MATCH-ATOM`,
  get:
    ({ route }: { route: string }) =>
    ({ get }) => {
      const { pathname } = get(hrefAtom_INTERNAL);
      const match = matchPath(pathname, { path: route });
      return match;
    },
});

function stringToNumber(): Checker<number> {
  return custom((value) => {
    const out = Number(value);
    if (Number.isNaN(out)) {
      return null;
    }
    return out;
  }, 'could not convert to number');
}

const primitive = (v: string) => {
  if (v.endsWith('(\\d+)')) {
    return stringToNumber();
  }
  if (v.endsWith('(\\d+)?')) {
    return voidable(stringToNumber());
  }
  if (v.endsWith('?')) {
    return voidable(string());
  }
  return string();
};

const cleanup = (v: string) => v.replace(/^:(.*?)(\(\\d\+\))?\??$/, '$1');

export function routeParamsSelector<R extends Route>(
  route: R
): RouteParamsReturnType<R> {
  const routeParams = route
    .split('/')
    .slice(1)
    .filter((v) => v.startsWith(':'));

  const checker = object(
    Object.fromEntries(
      routeParams.map((param) => [cleanup(param), primitive(param)])
    )
  );

  return selector({
    key: `@cherre-frontend/data-fetching/route-params-selector/${route}`,
    get: ({ get }) => {
      const match = get(matchCurrentPathname({ route }));

      if (!match) {
        return new Promise(() => {});
      }

      const result = checker(match.params);

      if (result.type === 'failure') {
        throw new Error(result.message);
      }

      return result.value as RouteParams<R>;
    },
  });
}

export type SearchParamsSelectorParams<R> = {
  key: string;
  routes: Route[];
  refine: Checker<R>;
  dependants?: RecoilState<any>[];
};

export const serialize = (x: any) => {
  const str = JSON.stringify(x);
  if (str === '{}') {
    return '';
  }
  const buffer = new TextEncoder().encode(str);
  const base64 = btoa(String.fromCharCode(...buffer));
  return encodeURIComponent(base64);
};

function base64ToString(base64) {
  const binary = atob(base64);
  const buffer = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i++) {
    buffer[i] = binary.charCodeAt(i);
  }
  return new TextDecoder().decode(buffer);
}

export const deserialize = (x: string) => {
  if (!x) {
    return {};
  }

  return JSON.parse(base64ToString(decodeURIComponent(x)));
};

const refineValue = <T>(refine: Checker<T>, value: T) => {
  const result = refine(value);
  if (result.type === 'failure') {
    throw new Error(result.message);
  }
  return result.value;
};

export function searchParamsSelector<R>(params: SearchParamsSelectorParams<R>) {
  const key = params.key;
  const refine = params.refine;
  const defaultValue = refineValue(
    refine,
    undefined as CheckerReturnType<typeof refine>
  );

  const node = selector({
    key: `@cherre-frontend/data-fetching/search-params-selector/${params.routes.join(
      '::'
    )}/${key}`,
    get: ({ get }) => {
      for (const route of params.routes) {
        const match = get(matchCurrentPathname({ route }));
        const { search } = get(hrefAtom_INTERNAL);

        if (!match) {
          continue;
        }

        if (!search) {
          return defaultValue;
        }

        const searchParams = deserialize(search);
        const result = refine(searchParams[key] || defaultValue);

        if (result.type === 'failure') {
          throw new Error(result.message);
        }

        return result.value;
      }
      throw new Promise<R>(() => {});
    },
    set: ({ set, get }, newValue) => {
      const { pathname, search } = get(hrefAtom_INTERNAL);

      for (const route of params.routes) {
        const match = get(matchCurrentPathname({ route }));

        if (!match) {
          continue;
        }

        const result = refine(newValue);
        if (result.type === 'failure') {
          throw new Error(result.message);
        }

        const searchParams = deserialize(search);

        if (isEqual(result.value, defaultValue)) {
          delete searchParams[key];
        } else {
          searchParams[key] = result.value;
        }

        set(hrefAtom_INTERNAL, (old) => ({
          ...old,
          search: serialize(searchParams),
        }));

        // add small timeout so the dependants get resetted after this selector changes value
        setTimeout(() => dependencyStore.resetDependantsFor(node), 100);
        return;
      }
      throw new Error(
        `Search params selector from "${params.routes.join(
          ', '
        )}" been used in "${pathname}"`
      );
    },
  });

  if (params.dependants) {
    dependencyStore.setDependantsFor(node, params.dependants);
  }

  return node;
}

export const RecoilRouteSyncStoreKey_INTERNAL = () =>
  RECOIL_ROUTER_STORE_KEY_INTERNAL;
