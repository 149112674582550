import React from 'react';
import { RecoilRouteSyncStoreKey_INTERNAL } from '../recoil/routeSelector';
import { useDependantsCallbacks_INTERNAL } from '../recoil/dependantsEffect';
import { RecoilURLSync } from 'recoil-sync';
import { useHistory } from 'react-router';

const serialize = ({
  root: { pathname = '', search = '', hash = '' } = {},
}: any) => {
  const urlObj = new URL(window.document.location.href);
  urlObj.pathname = pathname ?? '';
  urlObj.search = search ? '?' + search : '';
  urlObj.hash = hash ?? '';
  return urlObj.toString();
};

const deserialize = (url: string) => {
  const urlObj = new URL(`${window.document.location.origin}${url}`);
  const pathname = urlObj.pathname;
  const search = urlObj.search.replace(/^\?/, '');
  const hash = urlObj.hash;
  return { root: { pathname, search, hash } };
};

type RecoilRouteSync_INTERNALProps = {
  url?: string;
};

export const RecoilRouteSync_INTERNAL: React.FC<
  RecoilRouteSync_INTERNALProps
> = ({ children, url }) => {
  useDependantsCallbacks_INTERNAL();
  const history = useHistory();

  return (
    <RecoilURLSync
      location={{ part: 'href' }}
      serialize={serialize}
      deserialize={deserialize}
      storeKey={RecoilRouteSyncStoreKey_INTERNAL()}
      browserInterface={
        __TEST__
          ? {
              getURL: () => url ?? '/',
              pushURL: () => {},
              replaceURL: () => {},
              listenChangeURL: () => () => {},
            }
          : { listenChangeURL: history.listen }
      }
    >
      {children}
    </RecoilURLSync>
  );
};
