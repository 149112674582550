import React, { Suspense, useEffect } from 'react';
import { useRecoilCallback } from 'recoil';
import {
  ErrorBoundary_INTERNAL,
  ErrorBoundaryBehaviour,
} from './ErrorBoundary_INTERNAL';
import {
  useAppContext,
  disabledContext_INTERNAL,
  useDisabledContext,
} from '@cherre-frontend/core';
import { useDataFetchingConfig_INTERNAL } from '../hooks/useDataFetchingConfig_INTERNAL';
import { ErrorBoundaryComponent } from '../contexts/configContext';
import { suspenseContext_INTERNAL } from '../contexts/suspenseContext';
import { useIsSuspended } from '../hooks/useIsSuspended';

type StylingProps = {
  styles?: React.CSSProperties;
  className?: string;
  disableParent?: boolean;
};

const MainComponent: React.FC<StylingProps> = (props) => {
  const config = useDataFetchingConfig_INTERNAL();
  return (
    <suspenseContext_INTERNAL.Provider value={false}>
      <config.resumeWrapper {...props} />
    </suspenseContext_INTERNAL.Provider>
  );
};

const FallbackComponent: React.FC<StylingProps> = (props) => {
  const config = useDataFetchingConfig_INTERNAL();
  const [, setDisabled] = useDisabledContext();

  useEffect(() => {
    if (props.disableParent) {
      setDisabled(true);
      return () => setDisabled(false);
    }
  }, []);

  return (
    <disabledContext_INTERNAL.Provider value={[true, () => {}]}>
      <suspenseContext_INTERNAL.Provider value>
        <config.suspenseWrapper {...props} />
      </suspenseContext_INTERNAL.Provider>
    </disabledContext_INTERNAL.Provider>
  );
};

export type DataFetcherProps = {
  styles?: React.CSSProperties;
  className?: string;
  suspenseFallback?: React.ReactNode;
  errorFallback?: ErrorBoundaryComponent;
  behavior?: ErrorBoundaryBehaviour;
  waitForParent?: boolean;
  disableParent?: boolean;
  onError?: (error: unknown) => void;
  onRetry?: () => void;
};

export const DataFetcher: React.FC<DataFetcherProps> = ({
  children,
  suspenseFallback = children,
  errorFallback,
  behavior,
  waitForParent = false,
  onError,
  onRetry,
  styles,
  className,
  disableParent = true,
}) => {
  const config = useDataFetchingConfig_INTERNAL();
  const appContext = useAppContext();
  const isParentSuspended = useIsSuspended();
  const recoilRefresher = useRecoilCallback(({ refresh }) => refresh, []);

  const renderFallbackAsMain = waitForParent && isParentSuspended;

  const fallback = (
    <FallbackComponent
      styles={styles}
      className={className}
      disableParent={disableParent}
    >
      {suspenseFallback}
    </FallbackComponent>
  );
  const main = (
    <MainComponent styles={styles} className={className}>
      {children}
    </MainComponent>
  );

  return (
    <ErrorBoundary_INTERNAL
      appContext={appContext}
      behavior={behavior || config.defaultErrorBehavior}
      errorFallback={errorFallback || config.defaultErrorBoundary}
      onError={onError}
      onRetry={onRetry}
      recoilRefresher={recoilRefresher}
      styles={styles}
      className={className}
    >
      <Suspense fallback={fallback}>
        {renderFallbackAsMain ? fallback : main}
      </Suspense>
    </ErrorBoundary_INTERNAL>
  );
};
