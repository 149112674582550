import { useCherreValue } from './useCherreValue';
import { useCherreSetPartialState } from './useCherreSetPartialState';
import { CherreState } from '../recoil';

export const useCherrePartialState = <T extends Record<string, unknown>>(
  node: CherreState<T>
) => {
  const value = useCherreValue(node);
  const setter = useCherreSetPartialState(node);
  return [value, setter] as const;
};
