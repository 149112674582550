import React from 'react';
import { ErrorBoundaryComponent } from '../contexts/configContext';
import { RecoilValue } from 'recoil';
import { AppContext } from '@cherre-frontend/core';
import { DataFetchingError } from '../classes/error';

export type ErrorBoundaryBehaviour = 'hide' | 'fallback' | 'retry' | 'raise';

type ErrorBoundaryProps = {
  behavior: ErrorBoundaryBehaviour;
  errorFallback: ErrorBoundaryComponent;
  recoilRefresher: (node: RecoilValue<unknown>) => void;
  onRetry?: () => void;
  onError?: (error: unknown) => void;
  appContext: AppContext;
  styles?: React.CSSProperties;
  className?: string;
};

export class ErrorBoundary_INTERNAL extends React.Component<
  ErrorBoundaryProps,
  { error?: unknown }
> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, info) {
    this.props.appContext.logger.error(error, info);
    this.props.appContext.telemetry.captureException(error);
    this.props.onError?.(error);
  }

  clearError = () => {
    this.setState({ error: undefined });
  };

  onRetry = () => {
    if (this.props.onRetry) {
      this.props.onRetry();
    } else if (this.state.error instanceof DataFetchingError) {
      this.props.recoilRefresher(this.state.error.node);
    }
    this.clearError();
  };

  getNode = () => {
    if (this.state.error instanceof DataFetchingError) {
      return this.state.error.node;
    }
  };

  render() {
    const Component = this.props.errorFallback;

    if (!this.state.error) {
      return this.props.children;
    }

    switch (this.props.behavior) {
      case 'fallback':
        return (
          <Component
            styles={this.props.styles}
            className={this.props.className}
            error={this.state.error}
            clearError={this.clearError}
            showRetryButton={false}
          />
        );
      case 'retry':
        return (
          <Component
            styles={this.props.styles}
            className={this.props.className}
            error={this.state.error}
            onRetry={this.onRetry}
            clearError={this.clearError}
            node={this.getNode()}
            showRetryButton
          />
        );
      case 'raise':
        throw this.state.error;
      case 'hide':
      default:
        return null;
    }
  }
}
