import { SetterOrUpdater, useRecoilCallback } from 'recoil';
import { CherreState } from '../recoil';
import { useResolveNode } from './useRecoilScope';

type SetOrUpdateParams<T> = Parameters<SetterOrUpdater<T>>[0];

export const useCherreSetPartialState = <T extends Record<string, unknown>>(
  node: CherreState<T>
) => {
  const _node = useResolveNode(node);
  return useRecoilCallback(
    ({ set }) =>
      (params: SetOrUpdateParams<Partial<T>>) => {
        if (typeof params === 'function') {
          set(_node, (old) => ({ ...old, ...params(old) }));
        } else {
          set(_node, (old) => ({ ...old, ...params }));
        }
      },
    [_node]
  );
};
