import * as refine_INTERNAL from '@recoiljs/refine';
import moment_INTERNAL from 'moment';

type AnyArray<T = any> = Array<T> | ReadonlyArray<T>;

type FromArrayConstant<A extends AnyArray> = A extends AnyArray<infer T>
  ? T
  : never;

const stringLiterals = <A extends AnyArray>(
  arr: A
): refine_INTERNAL.Checker<FromArrayConstant<A>> => {
  return refine_INTERNAL.stringLiterals(
    Object.fromEntries(arr.map((i) => [i, i]))
  );
};

const moment = () =>
  refine_INTERNAL.custom(
    (value) => (moment_INTERNAL.isMoment(value) ? value : null),
    'value is not a valid Moment instance'
  );

const allowedNumbers = <A extends AnyArray<number>>(
  arr: A
): refine_INTERNAL.Checker<FromArrayConstant<A>> => {
  return refine_INTERNAL.custom<FromArrayConstant<A>>((value) => {
    return (arr.includes(value as any) ? value : null) as any;
  }, 'value is not an allowed number');
};

export const refine = {
  ...refine_INTERNAL,
  stringLiterals,
  moment,
  allowedNumbers,
};
export type {
  AssertionFunction,
  CheckFailure,
  CheckResult,
  CheckSuccess,
  Checker,
  CheckerReturnType,
  CoercionFunction,
} from '@recoiljs/refine';
