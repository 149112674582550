import { AtomEffect } from 'recoil';
import {
  addToStore_INTERNAL,
  removeFromStore_INTERNAL,
} from '../subscriptionStore_INTERNAL';

type SubscribeFn<T> = (params: { setSelf: (value: T) => void }) => () => void;

export const subscriptionEffect =
  <T>(subscribeFn: SubscribeFn<T>, initializeAtom = true): AtomEffect<T> =>
  (params) => {
    let cleanupfn: (() => void) | null = null;
    let subscribed = false;
    let isSet = true;
    let resolve: ((newValue: T) => void) | null = null;
    if (initializeAtom) {
      // if this effect initializes the atom it will watch for resets to prevent the unsubscribe from firing and living the atom on a loading state undefinitely
      isSet = false;
      params.setSelf(new Promise((res) => (resolve = res)));
      params.onSet((_, __, reset) => {
        isSet = !reset;
      });
    }
    const setSelf: (value: T) => void = (newValue) => {
      params.setSelf(newValue);
      resolve?.(newValue);
      resolve = null;
      isSet = true;
    };
    const unsubscribe = () => {
      if (!subscribed || !isSet) {
        return;
      }
      cleanupfn?.();
      cleanupfn = null;
      subscribed = false;
    };
    const subscribe = () => {
      if (subscribed) {
        return;
      }
      cleanupfn = subscribeFn({ setSelf }) || null;
      subscribed = true;
    };
    addToStore_INTERNAL(params.node, subscribe, unsubscribe);
    // initialize subscription
    subscribe();
    return () => {
      removeFromStore_INTERNAL(params.node, subscribe, unsubscribe);
      // last unsubscribe to avoid leakage
      unsubscribe();
    };
  };
