import { useEffect } from 'react';
import { RecoilState, AtomEffect, useRecoilCallback } from 'recoil';

type Node = RecoilState<any>;
let resetNode: ((node: Node) => void) | null = null;
const dependencyMap_INTERNAL = new Map<Node, Set<Node>>();

const setDependantsFor = (node: Node, dependants: Node[]) => {
  if (!dependencyMap_INTERNAL.has(node)) {
    dependencyMap_INTERNAL.set(node, new Set());
  }
  dependants.forEach((dependant) =>
    dependencyMap_INTERNAL.get(node)?.add(dependant)
  );
};

const setDependencyOn = (nodes: Node[], dependant: Node) => {
  nodes.forEach((node) => setDependantsFor(node, [dependant]));
};

const excludeDependantsFor = (node: Node) => {
  dependencyMap_INTERNAL.delete(node);
};

const excludeDependencyFrom = (nodes: Node[], dependant: Node) => {
  nodes.forEach((node) => dependencyMap_INTERNAL.get(node)?.delete(dependant));
};

const resetDependantsFor = (node: Node) => {
  dependencyMap_INTERNAL
    .get(node)
    ?.forEach((dependant) => resetNode?.(dependant));
};

export const dependencyStore = {
  setDependantsFor,
  setDependencyOn,
  excludeDependantsFor,
  excludeDependencyFrom,
  resetDependantsFor,
};

export const hasDependants =
  (...nodes: RecoilState<any>[]): AtomEffect<any> =>
  ({ onSet, node }) => {
    setDependantsFor(node, nodes);
    onSet(() => resetDependantsFor(node));
    return () => excludeDependantsFor(node);
  };

export const dependsOn =
  (...nodes: RecoilState<any>[]): AtomEffect<any> =>
  ({ node }) => {
    setDependencyOn(nodes, node);
    return () => excludeDependencyFrom(nodes, node);
  };

export const useDependantsCallbacks_INTERNAL = () => {
  const cbki = useRecoilCallback((_cbki) => () => _cbki, []);
  useEffect(() => {
    resetNode = (node) => cbki().reset(node);
    return () => {
      resetNode = null;
    };
  });
};
