import { EventOptions } from '@cherre-frontend/core';
import { SerializableParam } from 'recoil';
import { OperationType, commitMutation } from 'relay-runtime';
import { RelayEnvironmentStore_INTERNAL } from '../recoil/relayEnvironmentStore_INTERNAL';
import {
  CallbackRecoilContext,
  CherreCallbackInterface,
  useCherreEventWithRecoil,
} from './useCherreEventWithRecoil';
import { GraphQLReturn } from '../recoil/graphQLSelector';
import { useRecoilScope } from './useRecoilScope';
import { useCallback, useRef } from 'react';

function useEvent_INTERNAL<P, R, Params extends P[]>(
  cbk?: (...params: Params) => R
): (...params: Params) => R {
  const ref = useRef(cbk);
  ref.current = cbk;
  return useCallback<any>((...params: Params) => ref.current?.(...params), []);
}

type MapVariablesFuncType<
  M extends OperationType,
  V extends [] | [SerializableParam]
> = (
  ...variables: V
) => (callbacks: CherreCallbackInterface) => Promise<M['variables']>;

type MutationOptions<M extends OperationType> = EventOptions<
  M['response'],
  CallbackRecoilContext
> & {
  environment?: string;
};

export function useMutation<
  M extends OperationType,
  V extends [] | [SerializableParam]
>(
  query: GraphQLReturn<M>,
  options: MutationOptions<M> & {
    mapVariables: MapVariablesFuncType<M, V>;
  }
): (...param: V) => Promise<void>;

export function useMutation<M extends OperationType>(
  query: GraphQLReturn<M>,
  options: MutationOptions<M> & {
    mapVariables?: never;
  }
): (param: M['variables']) => Promise<void>;

export function useMutation<
  M extends OperationType,
  V extends [] | [SerializableParam]
>(
  query: GraphQLReturn<M>,
  options: MutationOptions<M> &
    (
      | {
          mapVariables: MapVariablesFuncType<M, V>;
        }
      | {
          mapVariables?: never;
        }
    )
) {
  const scope = useRecoilScope();
  return useCherreEventWithRecoil(
    query.default.fragment.name,
    (context) => async (params: any) => {
      const variables =
        (await (options?.mapVariables as any)?.(params)(context.recoil)) ||
        params;
      return new Promise<M['response']>((resolve, reject) => {
        RelayEnvironmentStore_INTERNAL.getEnvironment(
          scope['relayEnvironment'] || options?.environment
        ).then((env) => {
          const disposable = commitMutation(env, {
            mutation: query.default,
            variables: variables as any,
            onCompleted: (response) => resolve(response),
            onError: (error) => reject(error),
          });
          context.abortSignal.addEventListener('abort', () =>
            disposable.dispose()
          );
        });
      });
    },
    options
  );
}

export function useMutationAsync<M extends OperationType>(
  query: GraphQLReturn<M>,
  options?: MutationOptions<M>
) {
  const scope = useRecoilScope();

  return useEvent_INTERNAL(async (variables: M['variables']) => {
    const env = await RelayEnvironmentStore_INTERNAL.getEnvironment(
      scope['relayEnvironment'] || options?.environment
    );

    return new Promise<M['response']>((resolve, reject) => {
      commitMutation(env, {
        mutation: query.default,
        variables: variables as any,
        onCompleted: (response) => resolve(response),
        onError: (error) => reject(error),
      });
    });
  });
}
